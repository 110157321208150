import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import { Fade } from 'react-reveal';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import FooterItem from './FooterItem';
import styles from './styles.module.sass';
import Image from './Image';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logo: imageSharp(fluid: {originalName: {eq: "Drive-Logo-Text-2x.png"}}) {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
        phone1: imageSharp(fluid: {
          originalName : {eq: "Fleet-Driver-1.jpg"}
        }) {
          fluid(maxWidth: 796, maxHeight: 1544, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        phone2: imageSharp(fluid: {
          originalName : {eq: "Fleet-Driver-2.jpg"}
        }) {
          fluid(maxWidth: 796, maxHeight: 1544, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        phone3: imageSharp(fluid: {
          originalName : {eq: "Fleet-Driver-3.jpg"}
        }) {
          fluid(maxWidth: 796, maxHeight: 1544, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        phone4: imageSharp(fluid: {
          originalName : {eq: "Fleet-Driver-4.jpg"}
        }) {
          fluid(maxWidth: 796, maxHeight: 1544, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        phone5: imageSharp(fluid: {
          originalName : {eq: "Fleet-Driver-5.jpg"}
        }) {
          fluid(maxWidth: 796, maxHeight: 1544, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        phone6: imageSharp(fluid: {
          originalName : {eq: "Fleet-Driver-6.jpg"}
        }) {
          fluid(maxWidth: 796, maxHeight: 1544, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        narrow1: imageSharp(fluid: {
          originalName : {eq: "Fleet-Features-Driver-App-Narrow-1.jpg"}
        }) {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        narrow2: imageSharp(fluid: {
          originalName : {eq: "Fleet-Features-Driver-App-Narrow-2.jpg"}
        }) {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ logo, phone1, phone2, phone3, phone4, phone5, phone6, narrow1, narrow2 }) => (
      <>
        <SectionWrapper id={i18n('_hash:driver-app')}>
          <Grid item container className={
            clsx(
              styles.titleContainer,
              styles.productNameContainer
            )}>
              <Typography variant='h2' color='dark' weight='bold'
                align='left'>
                {i18n('Driver app')}
              </Typography>
            {/* <div className={styles.logoContainer}>
              <Img imgStyle={{ objectFit: 'contain' }} 
                objectFit='contain'
                fluid={logo.fluid}
                alt='Drive by OBI+'/>
            </div> */}
          </Grid>

          <Grid item container xs={12} md={12} xl={12}
            alignContent='center'
            justify='center'
            className={styles.container}>
            <Grid item xs={12} sm={12} md={12} className={clsx(
                styles.imageContainer,
                styles.imageContainerNarrow
              )}>
              <Fade bottom>
                <Img objectFit='contain'
                  className={styles.image}
                  fluid={narrow1.fluid} />
              </Fade>
            </Grid>
            <Image fluid={phone1.fluid}/>
            <Image fluid={phone2.fluid}
              delay={250}/>
            <Image fluid={phone3.fluid}
              delay={500}/>
          </Grid>
          <Grid container className={styles.footerContainer}
            alignContent='center'
            justify='flex-end'>
            <FooterItem text={i18n('Vehicle overview')}/>
            <FooterItem text={i18n('Automatic trip logging')}/>
            <FooterItem text={i18n('Trip categorization and quick notes')}/>
          </Grid>
        </SectionWrapper>        
        <SectionWrapper>
          <Grid item container xs={12} md={12} xl={12}
            alignContent='center'
            justify='center'
            className={styles.container}>
            <Grid item xs={12} sm={12} md={12} className={clsx(
                styles.imageContainer,
                styles.imageContainerNarrow
              )}>
              <Fade bottom>
                <Img objectFit='contain'
                  className={styles.image}
                  fluid={narrow2.fluid} />
              </Fade>
            </Grid>
            <Image fluid={phone4.fluid}
              alt='Driver App flaadestyringsloesning obiplus 1'/>
            <Image fluid={phone5.fluid}
              alt='Driver App flaadestyringsloesning obiplus 2'
              delay={250}/>
            <Image fluid={phone6.fluid}
              alt='Driver App flaadestyringsloesning obiplus 3'
              delay={500}/>
          </Grid>
          <Grid container className={styles.footerContainer}
            alignContent='center'
            justify='flex-end'>
            <FooterItem text={i18n('Driving logbook')}/>
            <FooterItem text={i18n('Communication')}/>
            <FooterItem text={i18n('Choose between multiple vehicles')}/>
          </Grid>
        </SectionWrapper>
      </>
    )}/>
);