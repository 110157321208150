import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import Feature from './Feature';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        fleet1: imageSharp(fluid: {
          originalName : {eq: "Fleet-1.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        fleet2: imageSharp(fluid: {
          originalName : {eq: "Fleet-2.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        fleet3: imageSharp(fluid: {
          originalName : {eq: "Fleet-3.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        fleet4: imageSharp(fluid: {
          originalName : {eq: "Fleet-4.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        fleet5: imageSharp(fluid: {
          originalName : {eq: "Fleet-5.jpg"}
        }) {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
        fleet6: imageSharp(fluid: {
          originalName : {eq: "Fleet-6.jpg"}
        }) {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }     
      }
    `}
    render={({ fleet1, fleet2, fleet3, fleet4, fleet5, fleet6 }) => (
      <SectionWrapper id={i18n('_hash:fleet-dashboard')}>
        <Typography variant='h2' color='dark' weight='bold'
          align='left'
          className={styles.titleContainer}>
          {i18n('Fleet web dashboard')}
        </Typography>
        <Grid item container xs={12} md={12} xl={12}
          alignContent='center'
          justify='center'
          className={styles.container}>
          <Feature title={i18n('Fleet management ')}
            text={i18n('Easy and simple fleet monitoring that gives you an overview of your fleet.')}
            image={fleet1}/>
          <Feature title={i18n('Automatic trip logging')}
            text={i18n('All journeys are automatically tracked. Relevant information about departure/arrival, addresses, duration, length, and type of trip are available, also in a historical view.')}
            image={fleet2}/>
          <Feature title={i18n('Fleet statistics')}
            text={i18n('The latest statistics about your fleet are always available. Get valuable insights about your fleet such as utilization, fuel consumption, and more.')}
            image={fleet3}/>
          <Feature title={i18n('Easy communication')}
            text={i18n('Through the in-built chat you can be in contact with your service provider (e.g. mechanic). In this way, you will be always updated about important events that occur.')}
            image={fleet4}/>
          <Feature title={i18n('Personalized reports')}
            text={i18n('You can enable relevant reports such as notification about overspeeding, driving logs, geofencing, or usage of the vehicles after working hours.')}
            image={fleet5}/>
          <Feature title={i18n('Geofencing')}
            text={i18n('Create different geofences to be notified when vehicles enter or exit a certain area to keep track of the daily workflow or avoid unauthorized usage.')}
            image={fleet6}/>
        </Grid>
      </SectionWrapper>
    )}/>
);